import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { SharedModule } from 'primeng/api';
import { AuthModule } from '@pages/public/auth/auth.module';
import { AuthenticationService } from '@common/auth/services/authentication.service';
import { SocketsService } from '@common/ws-notifications/sockets.service';
import { merge } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@NgModule({
  declarations: [],
  providers: [SocketsService],
  imports: [CoreModule, SharedModule, AuthModule],
})
export class WsNotificationsModule {
  constructor(
    private authService: AuthenticationService,
    private socketsService: SocketsService,
  ) {
    merge(
      this.authService.getIdentity().pipe(filter(Boolean)),
      this.authService.onLogin(),
      this.authService.refreshToken().pipe(
        switchMap(() => this.authService.getIdentity()),
        filter(Boolean),
      ),
    ).subscribe((user) => {
      const token = this.authService.getAccessToken().pipe(filter(Boolean));
      const refreshToken = () => this.authService.refreshToken();
      if (token) {
        this.socketsService
          .connect(token, refreshToken, user?.id as string)
          .subscribe();
      }
    });

    this.authService.onLogout().subscribe(() => {
      this.socketsService.disconnect();
    });
  }
}
