import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { CompaniesState } from '@store/companies/companies.state';
import { Company } from '@core/models/company.model';
import { DashboardState } from '@store/dashboard/dashboard.state';
import { DashboardStats } from '@core/models';
import { GetDashboard } from '@store/dashboard/dashboard.actions';

@Injectable()
export class DashboardStoreService {
  constructor(private store: Store) {}

  selectCompanyDashboard(): Observable<DashboardStats> {
    return this.store
      .select(DashboardState.getDashboardStats)
      .pipe(filter((data) => !!data)) as Observable<DashboardStats>;
  }

  getCompanyDashboard(period: [Date, Date]): Observable<DashboardStats> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new GetDashboard(company.id, period)),
      ),
      switchMap(() => this.selectCompanyDashboard()),
    );
  }
}
