import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Company } from '@core/models/company.model';
import { BaseFormDirective } from '@ui/forms/base-form.component';
import { CustomValidators } from '@common/shared/utils/validation/custom-validators';

@Component({
  selector: 'app-add-company-form',
  templateUrl: './add-company-form.component.html',
  styleUrls: ['./add-company-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCompanyFormComponent extends BaseFormDirective<Company> {
  override initForm(model?: Company) {
    this.form = this.fb.group({
      id: this.fb.control(model?.id),
      email: this.fb.control(model?.email, [
        Validators.required,
        CustomValidators.email,
      ]),
      name: this.fb.control(model?.name, [Validators.required]),
      description: this.fb.control(model?.description, [Validators.required]),
      logoUrl: this.fb.control(model?.logoUrl),
    });
  }
}
