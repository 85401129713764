import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { Category } from '@core/models/category.model';
import { CategoriesState } from '@store/categories/categories.state';
import { CompaniesState } from '@store/companies/companies.state';
import { Company } from '@core/models/company.model';
import {
  AddCategory,
  ClearCategoriesErrors,
  DeleteCategory,
  GetCategories,
  UpdateCategory,
} from '@store/categories/categories.actions';
import { CreateCategory } from '@core/models/create-category.model';
import { Nullable } from '@core/interfaces/nullable';

@Injectable()
export class CategoriesStoreService {
  constructor(private store: Store) {}

  selectLoadedCategories(): Observable<Category[]> {
    return this.store
      .select(CategoriesState.getCategories)
      .pipe(filter((data) => !!data)) as Observable<Category[]>;
  }

  getCategories(): Observable<Category[]> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new GetCategories(company.id)),
      ),
      switchMap(() => this.selectLoadedCategories()),
    );
  }

  error(): Observable<any> {
    return this.store.select(CategoriesState.getError).pipe(
      filter((data) => !!data),
      tap(() => {
        this.store.dispatch(new ClearCategoriesErrors());
      }),
    ) as Observable<any>;
  }

  createCategory(
    payload: CreateCategory,
    logo: Nullable<File>,
  ): Observable<Category> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new AddCategory(company.id, payload, logo)),
      ),
      switchMap(() => {
        return this.store
          .select(CategoriesState.getCreated)
          .pipe(filter((data) => !!data)) as Observable<Category>;
      }),
    );
  }

  updateCategory(
    id: string,
    payload: Partial<CreateCategory>,
    logo?: Nullable<File>,
  ): Observable<Category> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new UpdateCategory(id, company.id, payload, logo)),
      ),
      switchMap(() => {
        return this.store
          .select(CategoriesState.getUpdated)
          .pipe(filter((data) => !!data)) as Observable<Category>;
      }),
    );
  }

  deleteCategory(id: string): Observable<void> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new DeleteCategory(id, company.id)),
      ),
    );
  }
}
