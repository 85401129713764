import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import {
  DocumentShareRequest,
  DocumentShareResponse,
  PaginatedResponse,
} from '@core/models';
import {
  ApprovePendingRequest,
  ClearRequestsErrors,
  CreateDocumentRequest,
  DeclinePendingRequest,
  GetOutgoingRequests,
  GetPendingRequests,
} from '@store/requests/requests.actions';
import { RequestsState } from '@store/requests/requests.state';
import { CompaniesState } from '@store/companies/companies.state';
import { Company } from '@core/models/company.model';
import { CreateDocumentShareRequest } from '@core/models/create-document-share-request.model';
import { Nullable } from '@core/interfaces/nullable';

@Injectable()
export class RequestsStoreService {
  constructor(private store: Store) {}

  selectLoadedOutgoingRequests(
    where: Nullable<Record<string, string>>,
  ): Observable<DocumentShareRequest[]> {
    return this.store
      .select(RequestsState.selectOutgoingRequests(where))
      .pipe(filter((data) => !!data)) as Observable<DocumentShareRequest[]>;
  }

  selectLoadedPendingDocuments(
    where: Nullable<Record<string, string>>,
  ): Observable<PaginatedResponse<DocumentShareResponse>> {
    return this.store
      .select(RequestsState.selectPendingDocuments(where))
      .pipe(filter((data) => !!data)) as Observable<
      PaginatedResponse<DocumentShareResponse>
    >;
  }

  getOutgoingRequests(
    page: number,
    pageSize: number,
    sortBy?: string,
    order = 'asc',
    where?: Nullable<Record<string, string>>,
  ): Observable<PaginatedResponse<DocumentShareRequest>> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(
          new GetOutgoingRequests({
            companyId: company.id,
            page,
            pageSize,
            sortBy,
            order,
            where,
          }),
        ),
      ),
      switchMap(() => {
        return this.store
          .select(RequestsState.selectOutgoingRequests(where))
          .pipe(filter((data) => !!data)) as Observable<
          PaginatedResponse<DocumentShareRequest>
        >;
      }),
    );
  }

  getPendingDocuments(
    page: number,
    pageSize: number,
    sortBy?: string,
    order = 'asc',
    where?: Nullable<Record<string, string>>,
  ): Observable<PaginatedResponse<DocumentShareResponse>> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(
          new GetPendingRequests({
            companyId: company.id,
            page,
            pageSize,
            sortBy,
            order,
            where,
          }),
        ),
      ),
      switchMap(() => {
        return this.store
          .select(RequestsState.selectPendingDocuments(where))
          .pipe(filter((data) => !!data)) as Observable<
          PaginatedResponse<DocumentShareResponse>
        >;
      }),
    );
  }

  error(): Observable<any> {
    return this.store.select(RequestsState.getError).pipe(
      filter((data) => !!data),
      tap(() => {
        this.store.dispatch(new ClearRequestsErrors());
      }),
    ) as Observable<any>;
  }

  createDocumentRequest(
    payload: CreateDocumentShareRequest,
  ): Observable<boolean> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new CreateDocumentRequest(company.id, payload)),
      ),
      switchMap(() => {
        return this.store
          .select(RequestsState.getCreated)
          .pipe(filter((data) => !!data)) as Observable<boolean>;
      }),
    );
  }

  approveDocumentRequest(id: string): Observable<void> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new ApprovePendingRequest(company.id, id)),
      ),
    );
  }

  declineDocumentRequest(id: string): Observable<void> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new DeclinePendingRequest(company.id, id)),
      ),
    );
  }
}
