import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import {
  CreateDocumentShare,
  PaginatedResponse,
  SharedDocument,
} from '@core/models';
import { CompaniesState } from '@store/companies/companies.state';
import { Company } from '@core/models/company.model';
import { Nullable } from '@core/interfaces/nullable';
import { SharedDocumentsState } from '@store/shared-documents/shared-documents.state';
import {
  CanBeShared,
  ClearSharedDocumentsErrors,
  GetSharedByClients,
  GetSharedByCompany,
  GetSharedByDocument,
  RevokeDocumentAccess,
  ShareDocument,
} from '@store/shared-documents/shared-documents.actions';

@Injectable()
export class SharedDocumentsStoreService {
  constructor(private store: Store) {}

  selectSharedByCompany(
    where: Nullable<Record<string, string>>,
  ): Observable<PaginatedResponse<SharedDocument>> {
    return this.store
      .select(SharedDocumentsState.selectSharedByCompany(where))
      .pipe(filter((data) => !!data)) as Observable<
      PaginatedResponse<SharedDocument>
    >;
  }

  selectSharedByClients(
    where: Nullable<Record<string, string>>,
  ): Observable<PaginatedResponse<SharedDocument>> {
    return this.store
      .select(SharedDocumentsState.selectSharedByClients(where))
      .pipe(filter((data) => !!data)) as Observable<
      PaginatedResponse<SharedDocument>
    >;
  }

  selectSharedByDocument(
    documentId: string,
    where: Nullable<Record<string, string>>,
  ): Observable<PaginatedResponse<SharedDocument>> {
    return this.store
      .select(
        SharedDocumentsState.selectSharedByCompany({
          ...(where || {}),
          'doc.id': documentId,
        }),
      )
      .pipe(filter((data) => !!data)) as Observable<
      PaginatedResponse<SharedDocument>
    >;
  }

  getSharedByCompany(
    page: number,
    pageSize: number,
    sortBy?: string,
    order = 'asc',
    where?: Nullable<Record<string, string>>,
  ): Observable<PaginatedResponse<SharedDocument>> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(
          new GetSharedByCompany({
            companyId: company.id,
            page,
            pageSize,
            sortBy,
            order,
            where,
          }),
        ),
      ),
      switchMap(() => {
        return this.selectSharedByCompany(where);
      }),
    );
  }

  getSharedByClients(
    page: number,
    pageSize: number,
    sortBy?: string,
    order = 'asc',
    where?: Nullable<Record<string, string>>,
  ): Observable<PaginatedResponse<SharedDocument>> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(
          new GetSharedByClients({
            companyId: company.id,
            page,
            pageSize,
            sortBy,
            order,
            where,
          }),
        ),
      ),
      switchMap(() => {
        return this.selectSharedByClients(where);
      }),
    );
  }

  getSharedByDocument(
    documentId: string,
    page: number,
    pageSize: number,
    sortBy?: string,
    order = 'asc',
    where?: Nullable<Record<string, string>>,
  ): Observable<PaginatedResponse<SharedDocument>> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(
          new GetSharedByDocument({
            companyId: company.id,
            documentId,
            page,
            pageSize,
            sortBy,
            order,
            where,
          }),
        ),
      ),
      switchMap(() => {
        return this.selectSharedByDocument(documentId, where);
      }),
    );
  }

  error(): Observable<any> {
    return this.store.select(SharedDocumentsState.getError).pipe(
      filter((data) => !!data),
      tap(() => {
        this.store.dispatch(new ClearSharedDocumentsErrors());
      }),
    ) as Observable<any>;
  }

  shareDocument(payload: CreateDocumentShare): Observable<boolean> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new ShareDocument(company.id, payload)),
      ),
      switchMap(() => {
        return this.store
          .select(SharedDocumentsState.getShared)
          .pipe(filter((data) => !!data)) as Observable<boolean>;
      }),
    );
  }

  revokeDocumentAccess(id: string): Observable<SharedDocument> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new RevokeDocumentAccess(company.id, id)),
      ),
      switchMap(() => {
        return this.store
          .select(SharedDocumentsState.getRevoked)
          .pipe(filter((data) => !!data)) as Observable<SharedDocument>;
      }),
    );
  }

  canBeShared(documentId: string, email: string): Observable<boolean> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new CanBeShared(company.id, documentId, email)),
      ),
      switchMap(() => {
        return this.store
          .select(SharedDocumentsState.canBeShared(documentId, email))
          .pipe(
            filter((data) => typeof data === 'boolean'),
          ) as Observable<boolean>;
      }),
    );
  }
}
