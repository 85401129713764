import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { DateTimeService } from '@common/shared/services/date-time.service';
import { UsersService } from '@core/services/api';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(
    control: AbstractControl,
  ): ValidationErrors | null {
    if (control) {
      const password: string =
        control.parent && control.parent.get('password')
          ? control.parent?.get('password')?.value
          : null;
      const confirmPassword: string = control.value;
      if (password !== confirmPassword) {
        return { passwordMatch: true };
      }
    }
    return null;
  }

  static hasNumberValidator(control: AbstractControl): ValidationErrors | null {
    return CustomValidators.patternValidator(/\d/, { hasNumber: true }).call(
      this,
      control,
    );
  }

  static hasCapitalCaseCharacter(
    control: AbstractControl,
  ): ValidationErrors | null {
    return CustomValidators.patternValidator(/[A-Z]/, {
      hasCapitalCase: true,
    }).call(this, control);
  }

  static hasSmallCaseCharacter(
    control: AbstractControl,
  ): ValidationErrors | null {
    return CustomValidators.patternValidator(/[a-z]/, {
      hasSmallCase: true,
    }).call(this, control);
  }

  static hasSpecialCharacter(
    control: AbstractControl,
  ): ValidationErrors | null {
    return CustomValidators.patternValidator(
      /[!@#\$%^&*\(\)_+\-=\[\]\{\};':"|,.<>\?]/,
      { hasSpecialCharacter: true },
    ).call(this, control);
  }

  static existingPasswordValidator(
    service: UsersService,
    error: ValidationErrors,
  ): ValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return new Observable<ValidationErrors | null>((obs) => {
        if (control.value.length < 6) {
          obs.next(error);
          obs.complete();
          return;
        }
        service.checkPassword(control.value).subscribe(
          (resp) => {
            obs.next(null);
            obs.complete();
          },
          (err) => {
            obs.next(error);
            obs.complete();
          },
        );
      });
    };
  }

  static requiredObjectProps(keys: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let valid = true;

      if (!control.value) {
        valid = false;
      } else if (
        !Object.keys(control.value)
          .map((key: any) => control.value[key])
          .reduce((acc, cur) => acc && !!cur, true)
      ) {
        valid = false;
      }
      if (!valid) {
        return {
          requireObjectProperties: false,
        } as ValidationErrors;
      } else {
        return null;
      }
    };
  }

  static existingEmailValidator(email: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return of(null);
    };
  }

  static lessThanDate(fieldName: string): ValidatorFn {
    const dateService: DateTimeService = new DateTimeService();
    return (control: AbstractControl): ValidationErrors | null => {
      const date: string =
        control.parent && control.parent.get(fieldName)
          ? control.parent?.get(fieldName)?.value
          : null;
      if (!date) {
        return null;
      }
      if (!control.value) {
        return null;
      }

      return dateService.diff(control.value as Date, date) < 0
        ? { lessThanDate: true }
        : null;
    };
  }

  static contactName(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const contactPattern = new RegExp(
      '^([\\w\\+-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}|[\\w\\s]+\\s?<[\\w-\\+\\.]+@([\\w-]+\\.)+[\\w-]{2,4}>)$',
    );
    return !contactPattern.test(control.value) ? { contactName: true } : null;
  }

  static email(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const emailPattern = new RegExp(
      /[0-9a-zA-Z\\._-]@[a-zA-Z0-9\\._-]+\.[a-zA-Z]{2,4}$/,
    );
    return !emailPattern.test(control.value) ? { email: true } : null;
  }

  static greaterThanDate(fieldName: string): ValidatorFn {
    const dateService: DateTimeService = new DateTimeService();
    return (control: AbstractControl): ValidationErrors | null => {
      const date: string =
        control.parent && control.parent.get(fieldName)
          ? control.parent?.get(fieldName)?.value
          : null;
      if (!date) {
        return null;
      }
      if (!control.value) {
        return null;
      }

      return dateService.diff(control.value as Date, date) > 0
        ? { greaterThanDate: true }
        : null;
    };
  }

  static noWhitespace(control: AbstractControl) {
    const res = CustomValidators.patternValidator(/^(|\S.*)$/, {
      whitespace: true,
    }).call(this, control);

    console.log('is valid = ', res);

    return res;
  }
}
