import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { CompaniesState } from '@store/companies/companies.state';
import { Company } from '@core/models/company.model';
import {
  ContactGroup,
  CreateContactGroup,
  UpdateContactGroupDto,
} from '@core/models';
import { ContactGroupsState } from '@store/contact-groups/contact-groups.state';
import {
  AddContactGroup,
  ClearContactGroupsErrors,
  DeleteContactGroup,
  GetContactGroups,
  GetGroupCountersAction,
  UpdateContactGroup,
} from '@store/contact-groups/contact-groups.actions';

@Injectable()
export class ContactGroupsStoreService {
  constructor(private store: Store) {}

  selectLoadedContactGroups(): Observable<ContactGroup[]> {
    return this.store
      .select(ContactGroupsState.getContacts)
      .pipe(filter((data) => !!data)) as Observable<ContactGroup[]>;
  }

  selectGroupCounters(): Observable<Record<string, number>> {
    return this.store
      .select(ContactGroupsState.getGroupCounters)
      .pipe(filter((data) => !!data)) as Observable<Record<string, number>>;
  }

  getContactGroups(): Observable<ContactGroup[]> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new GetContactGroups(company.id)),
      ),
      switchMap(() => this.selectLoadedContactGroups()),
    );
  }

  loadGroupCounters(): Observable<Record<string, number>> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new GetGroupCountersAction(company.id)),
      ),
      switchMap(() => this.selectGroupCounters()),
    );
  }

  error(): Observable<any> {
    return this.store.select(ContactGroupsState.getError).pipe(
      filter((data) => !!data),
      tap(() => {
        this.store.dispatch(new ClearContactGroupsErrors());
      }),
    ) as Observable<any>;
  }

  createContactGroup(payload: CreateContactGroup): Observable<ContactGroup> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new AddContactGroup(company.id, payload)),
      ),
      switchMap(() => {
        return this.store
          .select(ContactGroupsState.getCreated)
          .pipe(filter((data) => !!data)) as Observable<ContactGroup>;
      }),
    );
  }

  updateContactGroup(
    id: string,
    payload: UpdateContactGroupDto,
  ): Observable<ContactGroup> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new UpdateContactGroup(id, company.id, payload)),
      ),
      switchMap(() => {
        return this.store
          .select(ContactGroupsState.getUpdated)
          .pipe(filter((data) => !!data)) as Observable<ContactGroup>;
      }),
    );
  }

  deleteContactGroup(id: string): Observable<void> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new DeleteContactGroup(id, company.id)),
      ),
    );
  }
}
