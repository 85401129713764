import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  CategoryShort,
  ContactGroup,
  CreateShareDocument,
  Document,
} from '@core/models';
import {
  BehaviorSubject,
  interval,
  Observable,
  ReplaySubject,
  Subject,
  switchMap,
} from 'rxjs';
import { Nullable } from '@core/interfaces/nullable';
import { Contact } from '@core/models/contact.model';
import { FormControl } from '@angular/forms';
import { FormHelper } from '@common/shared/helpers/form-helper';
import { delay, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { DocumentsStoreService } from '@store/documents/documents-store.service';
import {
  ConfirmationService,
  MessageService,
  PrimeIcons,
  SelectItemGroup,
} from 'primeng/api';
import { SharedDocumentsStoreService } from '@store/shared-documents/shared-documents-store.service';
import { DocumentSource } from '@core/enums/document-source.enum';
import { DateTimeService } from '@common/shared/services';
import { Form } from '@ui/forms/form.service';
import { SharingProgressStoreService } from '@store/sharing-progress/sharing-progress-store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProgressStatus } from '@common/shared/types/progress-status.enum';

@UntilDestroy()
@Component({
  selector: 'app-share-document',
  templateUrl: './share-document.component.html',
  styleUrls: ['./share-document.component.scss'],
  providers: [],
})
export class ShareDocumentComponent implements OnInit {
  private documentsWhere: Record<string, string> = {
    'document.source': DocumentSource.Company,
  };

  @Input() show!: boolean;

  @Input() categories!: CategoryShort[];
  @Input() contacts!: Contact[];
  @Input() groups!: ContactGroup[];
  @Input() disabled!: boolean;

  @Input() set document(document: Nullable<Document>) {
    this.documentValue = document;
    if (document) {
      this.documentReady$.next(document);
    }
  }

  get document(): Nullable<Document> {
    return this.documentValue;
  }

  @Input() shareModel: CreateShareDocument = {} as CreateShareDocument;

  @Output() showChange = new EventEmitter<boolean>();
  @Output() completed = new EventEmitter<boolean>();
  @Output() shareModelChange = new EventEmitter<CreateShareDocument>();
  @Output() documentChange = new EventEmitter<Document>();
  @Output() createContactOrGroup = new EventEmitter<'contact' | 'group'>();

  documentValue!: Nullable<Document>;
  processing$ = new BehaviorSubject<boolean>(false);
  documentReady$ = new ReplaySubject<Nullable<Document>>(1);
  docForm!: Form;

  loading$ = new BehaviorSubject<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  documents$: Observable<SelectItemGroup[]> = this.documentsService
    .selectLoadedPlainDocuments()
    .pipe(
      untilDestroyed(this),
      filter((data) => !!data),
      map((data) => {
        const res: SelectItemGroup[] = [];
        data.forEach((item) => {
          let cat = res.find((c) => c.label === item.category.name);
          if (!cat) {
            cat = {
              label: item.category.name,
              value: item.category.name,
              items: [],
            };
            res.push(cat);
          }
          if (cat.label === item.category.name) {
            cat.items.push(item as any);
          }
        });
        return res;
      }),
      tap((data) => {
        this.loading$.next(false);
        this.cdr.detectChanges();
      }),
    );

  constructor(
    private cdr: ChangeDetectorRef,
    private formHelper: FormHelper,
    private documentsShareService: SharedDocumentsStoreService,
    private documentsService: DocumentsStoreService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dateService: DateTimeService,
    private sharingStoreService: SharingProgressStoreService,
  ) {}

  ngOnInit(): void {
    this.documentsShareService
      .error()
      .pipe(untilDestroyed(this))
      .subscribe((error) => {
        this.messageService.clear();
        this.messageService.addAll([
          {
            severity: 'error',
            detail: error.error?.message || 'Something goes wrong',
          },
        ]);
        this.completed.emit(false);
      });

    this.documentsService
      .loadPlainDocuments(this.documentsWhere)
      .pipe(take(1))
      .subscribe();

    this.completed
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res) {
          this.onCancel();
          this.cdr.markForCheck();
        } else {
          this.processing$.next(false);
        }
      });
  }

  onFormInit(form: Form): void {
    this.docForm = form;
    this.docForm.getForm().addControl('document', new FormControl(''));
    this.documentReady$.pipe(untilDestroyed(this), take(1)).subscribe(() => {
      this.docForm.getForm().patchValue({
        document: this.document?.id,
      });
      this.cdr.markForCheck();
    });
    this.docForm
      .getForm()
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.docForm.clearValidation();
        this.cdr.markForCheck();
      });
    this.cdr.markForCheck();
  }

  onCancel(): void {
    this.docForm.reset({
      lifetime: '1',
    });
    this.document = null;
    this.processing$.next(false);
    this.showChange.emit(false);
    this.cdr.detectChanges();
  }

  onApply(): void {
    this.docForm.validate();
    this.cdr.detectChanges();
    this.messageService.clear();

    if (this.docForm.getForm().valid && this.document) {
      this.sharingStoreService
        .selectActiveTaskProgress()
        .pipe(untilDestroyed(this), take(1))
        .subscribe((inProgress) => {
          if (
            inProgress &&
            inProgress.find(
              (p) =>
                p.document?.id === this.document?.id &&
                p.status !== ProgressStatus.Completed,
            )
          ) {
            this.messageService.addAll([
              {
                severity: 'warn',
                detail:
                  'This file is being shared. It takes longer than we expected. Please wait until the sharing process is complete and you receive a notification about it.',
                summary: 'Sharing is temporary disabled',
                life: 15000,
              },
            ]);
          } else {
            this.save();
          }
        });
    }
  }

  onSearch(event: any): void {
    this.documentsService
      .loadPlainDocuments({
        ...this.documentsWhere,
        verb: event.filter || '',
      })
      .pipe(take(1))
      .subscribe();
  }

  onLazyLoad(event: any): void {
    console.log(event);
  }

  onCreateNewContact(event: 'contact' | 'group'): void {
    this.createContactOrGroup.emit(event);
  }

  private save() {
    this.processing$.next(true);
    this.cdr.detectChanges();

    if (this.shareModel?.lifetimeCustomPeriod) {
      this.shareModel.lifetime = Math.abs(
        Math.ceil(
          this.dateService.diff(
            new Date(),
            this.shareModel.lifetimeCustomPeriod,
            'hour',
          ) / 24,
        ),
      );
      if (!this.shareModel.lifetime) {
        this.shareModel.lifetime = 1;
      }
    }

    const contacts = this.shareModel?.contacts || [];

    const create = () => {
      this.documentsShareService
        .shareDocument({
          shareWith: contacts,
          document: this.document?.id as string,
          lifetime: parseInt(this.shareModel.lifetime as string),
          category: this.shareModel.category,
          message: this.shareModel.message,
        })
        .pipe(
          take(1),
          untilDestroyed(this),
          tap(() => {
            this.documentsService
              .loadDocumentsCountByCategory()
              .pipe(take(1))
              .subscribe();
          }),
          take(1),
        )
        .subscribe(() => {
          this.messageService.addAll([
            {
              severity: 'success',
              detail: 'Document has been successfully shared',
            },
          ]);
          this.shareModel = {} as CreateShareDocument;
          this.docForm.reset();
          this.processing$.next(false);
          this.completed.emit(true);
        });
    };
    const ref = this.confirmationService.confirm({
      message: `You are going to share the document with ${contacts.length} contacts. Do you want to continue?`,
      acceptLabel: 'Confirm',
      rejectLabel: 'Cancel',
      acceptIcon: PrimeIcons.CHECK,
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-outlined',
      closeOnEscape: true,
      accept: () => {
        create();
      },
      reject: () => {
        this.processing$.next(false);
        this.cdr.detectChanges();
      },
    });
  }
}
