import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class FormApplyService {
  private apply$ = new Subject<void>();
  private cancel$ = new Subject<void>();
  private validate$ = new Subject<void>();
  private valid$ = new Subject<boolean>();
  private reset$ = new Subject<void>();

  constructor() {}

  apply(): void {
    this.apply$.next();
  }

  cancel(): void {
    this.cancel$.next();
  }

  validate(): void {
    this.validate$.next();
  }

  setValid(): void {
    this.valid$.next(true);
  }

  setInvalid(): void {
    this.valid$.next(false);
  }

  reset(): void {
    this.reset$.next();
  }

  onApply(): Observable<void> {
    return this.apply$.asObservable();
  }

  onCancel(): Observable<void> {
    return this.cancel$.asObservable();
  }

  onReset(): Observable<void> {
    return this.reset$.asObservable();
  }

  onValidate(): Observable<void> {
    return this.validate$.asObservable();
  }

  onValidateResult(): Observable<boolean> {
    return this.valid$.asObservable();
  }
}
