import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, withLatestFrom } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { CompaniesState } from '@store/companies/companies.state';
import { Company } from '@core/models/company.model';
import {
  AddContact,
  ClearContactsErrors,
  ClearExternalContactsSearch,
  DeleteContact,
  GetContacts,
  SearchFyioExternalContact,
  GetContactsByGroup,
  UpdateContact,
} from '@store/contacts/contacts.actions';
import { CreateContact } from '@core/models/create-contact.model';
import { ContactsState } from '@store/contacts/contacts.state';
import { Contact } from '@core/models/contact.model';
import { Nullable } from '@core/interfaces/nullable';

@Injectable()
export class ContactsStoreService {
  constructor(private store: Store) {}

  selectLoadedContacts(): Observable<Contact[]> {
    return this.store
      .select(ContactsState.getContacts)
      .pipe(filter((data) => !!data)) as Observable<Contact[]>;
  }

  selectFyoExternalContact(): Observable<Nullable<Contact>> {
    return this.store.select(
      ContactsState.getFyioExternalContact,
    ) as Observable<Contact>;
  }

  selectContactsByGroup(id: string): Observable<Contact[]> {
    return this.store
      .select(ContactsState.getContactsByGroup(id))
      .pipe(filter((data) => !!data)) as Observable<Contact[]>;
  }

  loadContacts(force = false): Observable<Contact[]> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      withLatestFrom(this.store.select(ContactsState.getContacts)),
      tap(([company, contacts]) => {
        if (company && (!contacts || force)) {
          this.store.dispatch(new GetContacts(company?.id));
        }
      }),
      switchMap(() => this.selectLoadedContacts()),
    );
  }

  searchFyioExternalContact(email: string): Observable<Nullable<Contact>> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new SearchFyioExternalContact(company.id, email)),
      ),
      switchMap(() => this.selectFyoExternalContact()),
    );
  }

  clearExternalContactsSearch(): void {
    this.store.dispatch(new ClearExternalContactsSearch());
  }

  loadContactsByGroup(id: string): Observable<Contact[]> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      tap((company: Company) => {
        this.store.dispatch(new GetContactsByGroup(company.id, id));
      }),
      switchMap(() => this.selectContactsByGroup(id)),
    );
  }

  error(): Observable<any> {
    return this.store.select(ContactsState.getError).pipe(
      filter((data) => !!data),
      tap(() => {
        this.store.dispatch(new ClearContactsErrors());
      }),
    ) as Observable<any>;
  }

  createContact(payload: CreateContact): Observable<Contact> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new AddContact(company.id, payload)),
      ),
      switchMap(() => {
        return this.store
          .select(ContactsState.getCreated)
          .pipe(filter((data) => !!data)) as Observable<Contact>;
      }),
    );
  }

  updateContact(
    id: string,
    payload: Partial<CreateContact>,
  ): Observable<Contact> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new UpdateContact(id, company.id, payload)),
      ),
      switchMap(() => {
        return this.store
          .select(ContactsState.getUpdated)
          .pipe(filter((data) => !!data)) as Observable<Contact>;
      }),
    );
  }

  deleteContact(id: string): Observable<void> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take<Company>(1),
      switchMap((company: Company) =>
        this.store.dispatch(new DeleteContact(id, company.id)),
      ),
    );
  }
}
