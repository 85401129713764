import {
  Input,
  Output,
  OnInit,
  Component,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { FormHelper } from '@common/shared/helpers/form-helper';
import { Router } from '@angular/router';
import { CustomValidators } from '@common/shared/utils/validation/custom-validators';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit {
  @Input() title = 'Sign In';
  @Input() buttonText = 'Sign In';
  @Input() processing: boolean | undefined;

  @Output() login: EventEmitter<any> = new EventEmitter();
  @Output() loginWithGoogle: EventEmitter<any> = new EventEmitter();
  @Output() loginWithFacebook: EventEmitter<any> = new EventEmitter();

  form!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private logger: NGXLogger,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: this.fb.control('', [Validators.required, CustomValidators.email]),
      password: this.fb.control('', Validators.required),
    });
  }

  onGoogleSubmit(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.loginWithGoogle.emit();
  }

  onSubmit(event: any): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this?.form?.valid) {
      return;
    }

    this.login.emit(this.form.value);
  }

  navigateToRegister(): void {
    this.router.navigate(['/auth/register']);
  }
}
